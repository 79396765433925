<template>
	<div class="recruit">
		<Topq></Topq>
		<div class="recruit-content">
			<div class="main">
				<div class="main-card" v-if="!showCard">
					<div class="card-left">
						<img :src="userInfo.avatar" >
						<div class="card-left-msg">
							{{recruitname}}<span> · {{recruitzhi}}</span>
						</div>
					</div>
					<div class="card-right" @click="showcards">
						<img src="../assets/image/w.png" >
						<span>修改我的招聘名片</span>
					</div>
				</div>
				<div class="main-card2" v-if="showCard">
					<div class="recruit-content-title">
										编辑我的招聘名片
									</div>
									<div class="recruit-content-msg">
										<div class="logo-right">
											<div class="logo-img">
												<img :src="userInfo.avatar">
											</div>
											<div class="logo-set" @click.stop="uploadHeadImg">
												上传头像
												<input type="file" accept="image/*" @change="handleFile" class="hiddenInput" />
											</div>
										</div>
										<div class="recruit-msg">
											<div class="recruit-name">
												<span>昵称：</span>
												<div class="channel">
													<el-input v-model="recruitname" placeholder="例：李女生"></el-input>
												</div>
											</div>
											<div class="recruit-name">
												<span>职位：</span>
												<div class="channel">
													<el-input v-model="recruitzhi" placeholder="公司职位"></el-input>
												</div>
											</div>
										</div>
									</div>
									<div class="recruit-content-title">
										上传公司环境图片
									</div>
									<div class="recruit-content-compute">
					
										<div class="compute-img">
											<img :src="userInfo.avatar2">
										</div>
										<div class="logo-set" @click.stop="uploadHeadImgs">
											上传图片
											<input type="file" accept="image/*" @change="handleFiles" class="hiddenInputs" />
										</div>
					
									</div>
									<div class="treatment">
										<div class="treatment-content-title">
											福利待遇
										</div>
										<div class="treatment-content-time">
											<div class="treatment-time-item">
												<span>上班时间：</span>
												<div class="time-item-main">
													<div class="select-main">
														<el-time-select placeholder="起始时间" v-model="startime" :picker-options="{
					start: '00:00',step: '00:05',end: '24:00'}">
														</el-time-select>
													</div>
													<div class="zhi">至</div>
													<div>
														<el-time-select placeholder="结束时间" v-model="endtime" :picker-options="{
													start: '00:00',step: '00:05',end: '24:00'}">
														</el-time-select>
													</div>
												</div>
					
											</div>
											<div class="treatment-day-item">
												<span>上班日期：</span>
												<div class="day-item-main">
													<div class="select-main">
													<el-select v-model="starday" placeholder="请选择">
														<el-option v-for="item in stardayoption" :key="item.value" :label="item.label"
															:value="item.value">
														</el-option>
													</el-select>
													</div>
													<div class="zhis">至</div>
													<el-select v-model="enday" placeholder="请选择">
														<el-option v-for="item in endayoption" :key="item.value" :label="item.label"
															:value="item.value">
														</el-option>
													</el-select>
												</div>
											</div>
										</div>
										<div class="treatment-content-welfare">
											<div class="selfCheckBox" @click="clickCheckBox(index)" v-for="( item,index) in checkBoxArr"
												:key="index">
												<div class="selfCkeckImgBox-item" v-show="item.selectStatus==1"><img
														:src="checkBoxImg[index].icon">{{item.name}}</div>
												<div class="selfCkeckImgBox-item2" v-show="item.selectStatus==0"><img
														:src="checkBoxImg[index].icon">{{item.name}}</div>
											</div>
					
										</div>
										<div class="treatment-content-submit">
											<div class="submit-item">
												<div class="wanted-item-right-delete" @click="cancel"
												style="margin-right: 1rem;">
													取消
												</div>
												<div class="wanted-item-right-change" @click="edit">
													提交
												</div>
											</div>
										</div>
				</div>
				</div>
					<div class="treatment-content-pipei">
						<div class="wanted-top">
							<div class="top-wanted">你需要招聘什么岗位的员工？</div>
							<div class="top-num"><span>{{num}}</span>/20</div>
						</div>
						<div class="wanted-banner">
							编辑你的需求，匹配更多精准优秀人材
						</div>
						<div @click="add" v-if="pubPostLists!=undefined&&pubPostLists.length>0" class="add-main">
							<div  v-if="pubPostLists.length<20" class="add-content">
								<img src="../assets/image/add.png" >
								<span>添加招聘期望</span>
							</div>
						</div>
						<div @click="add" v-if="pubPostLists!=undefined&&pubPostLists.length==0" class="add-main2">
							<div  v-if="pubPostLists.length==0" class="add-content2">
								<img src="../assets/image/add.png" >
								<span>添加招聘期望</span>
							</div>
						</div>
						<div class="wanted-item">
							<div class="wanted-item-main" v-for="(item,index) in pubPostLists" :key="index">
								<div class="wanted-item-left">
									<div class="wanted-item-left-title">
										[{{pcaaProvince(item.province)}}
										{{pcaaCity(item.province,item.city)}}]   {{item.postTitle}}
									</div>
									<div class="wanted-item-left-main">
										<div class="price">{{price(item.salaryType)}}元</div>
										<div class="dizhi">{{item.category}}</div>
									</div>
								</div>
								<div class="wanted-item-right">
									<div class="wanted-item-right-deletes" @click="deleteItem(item.postId)">
										删除岗位
									</div>
									<div class="wanted-item-right-changes" @click="recruitSet(item.postId)">
										修改岗位
									</div>
								</div>

							</div>

						</div>
						
					</div>
				
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	const OSS = require('ali-oss');
	import md5 from 'js-md5'
	import $http from '../common/api/axios.js'
	import {
		userProfile,
		upConf,
		// editUserProfile,
		benefits,
		editBenefits,
		pubPostList,
		delPubPost
	} from '../common/api/api.js'
	import {
		pcaa
	} from 'area-data';
	import Topq from '../components/common/Topq.vue'
	import Footer from './common/Footer.vue'
	export default {
		data() {
			return {
				showCard:false,
				cardObj: {},
				userInfo: {
					avatar: '',
					avatar2: '',
				},
				recruitname: '',
				recruitzhi: '',
				startime: '',
				endtime: '',
				starday: '',
				enday: '',
				suffix: '',
				suffixs: '',
				stardayoption: [{
					value: '1',
					label: '周一'
				}, {
					value: '2',
					label: '周二'
				}, {
					value: '3',
					label: '周三'
				}, {
					value: '4',
					label: '周四'
				}, {
					value: '5',
					label: '周五'
				}, {
					value: '6',
					label: '周六'
				}, {
					value: '7',
					label: '周日'
				}],
				endayoption: [{
					value: '1',
					label: '周一'
				}, {
					value: '2',
					label: '周二'
				}, {
					value: '3',
					label: '周三'
				}, {
					value: '4',
					label: '周四'
				}, {
					value: '5',
					label: '周五'
				}, {
					value: '6',
					label: '周六'
				}, {
					value: '7',
					label: '周日'
				}],
				checkBoxImg: [{
						icon: require('../assets/image/welfare1.png')
					},
					{
						icon: require('../assets/image/welfare2.png')
					},
					{
						icon: require('../assets/image/welfare3.png')
					},
					{
						icon: require('../assets/image/welfare4.png')
					},
					{
						icon: require('../assets/image/welfare5.png')
					},
					{
						icon: require('../assets/image/welfare6.png')
					},
					{
						icon: require('../assets/image/welfare7.png')
					},
					{
						icon: require('../assets/image/welfare8.png')
					},
					{
						icon: require('../assets/image/welfare9.png')
					},
				],
				checkBoxArr: [{
						type: 1,
						name: '五险一金',
						selectStatus: 0,
					},
					{
						type: 2,
						name: '定期体检',
						selectStatus: 0,
					},
					{
						type: 3,
						name: '加班补助',
						selectStatus: 0,
					},
					{
						type: 4,
						name: '年终奖励',
						selectStatus: 0,
					},
					{
						type: 5,
						name: '带薪年假',
						selectStatus: 0,
					},
					{
						type: 6,
						name: '员工旅游',
						selectStatus: 0,
					},
					{
						type: 7,
						name: '节日福利',
						selectStatus: 0,
					},
					{
						type: 8,
						name: '零食下午茶',
						selectStatus: 0,
					},
					{
						type: 9,
						name: '交通补助',
						selectStatus: 0,
					},
				],
				changeBoolean: false,
				pubPostLists: [],
				changeBooleans: false,
			}
		},
		computed: {
			num() {
				if (this.pubPostLists) {
					return this.pubPostLists.length
				} else {
					return 0
				}
			}
		},
		components: {
			Topq,
			Footer

		},
		methods: {
			showcards(){
				this.showCard=!this.showCard
			},
			price(salaryType) {
				let xinoptions = this.$store.state.xinoptions;
				return xinoptions[salaryType].label
			},
			cancel() {
				this.showCard=false
				this.checkBoxArr = [{
						type: 1,
						name: '五险一金',
						selectStatus: 0,
					},
					{
						type: 2,
						name: '定期体检',
						selectStatus: 0,
					},
					{
						type: 3,
						name: '加班补助',
						selectStatus: 0,
					},
					{
						type: 4,
						name: '年终奖励',
						selectStatus: 0,
					},
					{
						type: 5,
						name: '带薪年假',
						selectStatus: 0,
					},
					{
						type: 6,
						name: '员工旅游',
						selectStatus: 0,
					},
					{
						type: 7,
						name: '节日福利',
						selectStatus: 0,
					},
					{
						type: 8,
						name: '零食下午茶',
						selectStatus: 0,
					},
					{
						type: 9,
						name: '交通补助',
						selectStatus: 0,
					},
				];
				this.enday = '';
				this.endtime = '';
				this.starday = '';
				this.startime = ''

			},
			deleteItem(postId) {
				let id = postId
				$http.request({
					url: delPubPost,
					data: {
						"postId": id
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$router.go(0)
						this.$message({
							message: '删除成功!',
							type: 'success',
							duration:'2000'
						});
					}
				}).catch(() => {

				})
			},
			edit() {
				let key
				let key2
				if (this.changeBoolean == false) {
					key = ''
				} else {
					let id = localStorage.getItem("id");
					key = id + "/part_" + md5(this.userInfo.avatar) + this.suffix;
					this.h5_url_to_blob(this.userInfo.avatar).then((res) => {
						let sign = this.$store.state.clientObj
						let client = new OSS({
							accessKeyId: sign.accessKeyId, // 后台的临时签名ID
							accessKeySecret: sign.accessKeySecret, // 后台的临时签名密钥
							stsToken: sign.securityToken,
							endpoint: sign.endPoint, // 上传后的域名
							bucket: sign.bucket, // OSS仓库名
							expireTime: sign.expireTime
						});
						async function putObject() {
							try {
								await client.put(key, res);

							} catch (e) {
								// console.log(e);
							}
						}
						putObject();
					})
				}
				if (this.changeBooleans == false) {
					key2 = ''
				} else {
					let id = localStorage.getItem("id");
					key2 = id + "/part_" + md5(this.userInfo.avatar2) + this.suffixs;
					this.h5_url_to_blob(this.userInfo.avatar2).then((res) => {
						let sign = this.$store.state.clientObj
						let client = new OSS({
							accessKeyId: sign.accessKeyId, // 后台的临时签名ID
							accessKeySecret: sign.accessKeySecret, // 后台的临时签名密钥
							stsToken: sign.securityToken,
							endpoint: sign.endPoint, // 上传后的域名
							bucket: sign.bucket, // OSS仓库名
							expireTime: sign.expireTime
						});
						async function putObject() {
							try {
								await client.put(key2, res);

							} catch (e) {
								// console.log(e);
							}
						}
						putObject();
					})
				}
				let that = this
				// $http.request({
				// 	url: editUserProfile,
				// 	data: {
				// 		"headImg": key,
				// 		"nickName": that.recruitname,
				// 		"position": that.recruitzhi,
				// 	}
				// }).then((res) => {
				// 	// console.log(res)
				// }).catch(() => {

				// });
				let temp = []
				this.checkBoxArr.forEach((item, index) => {
					if (item.selectStatus == 1) {
						temp.push(index + 1)
					}
				});
				$http.request({
					url: editBenefits,
					data: {
						"logo": key2,
						"benefitNums": temp,
						"endDay": that.enday,
						"endTime": that.endtime.trim(),
						"startDay": that.starday,
						"startTime": that.startime.trim()
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: '修改成功!',
							type: 'success',
							duration:'2000'
						});
					}
				}).catch(() => {

				});
				that.$router.push('./pj-recruit')

			},
			h5_url_to_blob(url) {
				return new Promise((resolve) => {
					var xhr = new XMLHttpRequest();
					xhr.open('GET', url, true);
					xhr.responseType = 'blob';
					xhr.onload = function() {
						if (this.status == 200) {
							var Blob = this.response;
							// console.log(myBlob)
							resolve(Blob)
							// myBlob现在是对象URL指向的blob。 
						}
					};
					xhr.send();
				})
			},
			pcaaProvince(province) {
				let num1 = 86
				let num2 = province
				return pcaa[num1][num2]
			},
			pcaaCity(province, city) {
				let num1 = province
				let num2 = city
				return pcaa[num1][num2]

			},
			// 打开图片上传
			uploadHeadImg: function() {
				this.$el.querySelector('.hiddenInput').click()
				this.changeBoolean = true
			},
			// 将头像显示
			handleFile: function(e) {
				let $target = e.target || e.srcElement
				let file = $target.files[0]
				let name = file.name
				this.suffix = name.substring(name.length - 4)
				var reader = new FileReader()
				reader.onload = (data) => {
					let res = data.target || data.srcElement
					this.userInfo.avatar = res.result
				}
				reader.readAsDataURL(file)
			},
			// 打开图片上传
			uploadHeadImgs: function() {
				this.$el.querySelector('.hiddenInputs').click()
				this.changeBooleans = true
			},
			// 将头像显示
			handleFiles: function(e) {
				let $target = e.target || e.srcElement
				let file = $target.files[0]
				let name = file.name
				this.suffixs = name.substring(name.length - 4)
				var reader = new FileReader()
				reader.onload = (data) => {
					let res = data.target || data.srcElement
					this.userInfo.avatar2 = res.result
				}
				reader.readAsDataURL(file)
			},

			clickCheckBox(index) {
				if (this.checkBoxArr[index].selectStatus == 1) {
					this.checkBoxArr[index].selectStatus = 0;
				} else {
					this.checkBoxArr[index].selectStatus = 1
				}
			},
			recruitSet(id) {
				this.$router.push({
					path: '/pj-post',
					query: {
						id: id
					}
				})
			},
			add() {
				this.$router.push('./pj-post')
			}

		},
		mounted() {

		},
		created() {
			let that = this
			this.$store.dispatch('initDic').then(() => {
				$http.request({
					url: upConf
				}).then((res) => {
					that.$store.state.clientObj = res.data
				}).catch(() => {

				});
				$http.request({
					url: userProfile
				}).then((res) => {
					that.userInfo.avatar = res.data.headImg;
					that.recruitname = res.data.nickName;
					that.recruitzhi = res.data.position
				}).catch(() => {

				});
				$http.request({
					url: benefits
				}).then((res) => {

					that.userInfo.avatar2 = res.data.logo;
					that.checkBoxArr = res.data.benefits;
					that.enday = res.data.endDay.trim();
					that.endtime = res.data.endTime.trim();
					that.starday = res.data.startDay;
					that.startime = res.data.startTime
				}).catch(() => {

				});
				$http.request({
					url: pubPostList
				}).then((res) => {

					that.pubPostLists = res.data.pubPostLists
				}).catch(() => {

				});
			})
		}
	}
</script>

<style scoped="scoped">
	.add-content2 img{
		margin-right: .1rem;
		width: .24rem;
	}
	.add-main2{
		margin-bottom: .2rem;
		width: 100%;
		height: 1rem;
		display: flex;
		justify-content: center;
		font-family: PingFang SC;
	}
	.add-content2{
		display: flex;
		justify-content: center;
		align-items: center;
		right: .6rem;
		top:.2rem;
		width: 5.67rem;
		height: .81rem;
		background: #E41D1D;
		border-radius: .04rem;
		color: #FFFFFF;
		font-size: .18rem;
	}
	.add-main{
		margin-bottom: .2rem;
		width: 100%;
		height: 1rem;
		position: relative;
		font-family: PingFang SC;
	}
	.add-content{
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: .6rem;
		top:.2rem;
		width: 2.2rem;
		height: .48rem;
		background: #E41D1D;
		border-radius: .04rem;
		color: #FFFFFF;
		font-size: .18rem;
	}
	.add-content img{
		margin-right: .1rem;
		width: .24rem;
	}
	.card-left{
		display: flex;
		align-items: center;
	}
	.card-left img{
		margin-left: .4rem;
		margin-right: .3rem;
		width: .88rem;
		height: .88rem;
		border-radius: .44rem;
	}
	.card-right{
		position: absolute;
		display: flex;
		align-items: center;
		right: 0.4rem;
		top: 0.3rem;
		cursor: pointer;
	}
	.card-right img{
		margin-right: .1rem;
		width: .17rem;
	}
	.card-right span{
		font-size: .18rem;		
		font-weight: 400;
		color: #333333;
	}
	.card-left-msg{
		font-size: .24rem;
		font-weight: 400;
		color: #333333;
		
	}
	.card-left-msg span{
		color: #9D9D9D;
	}
	.main-card{
		position: relative;
		width: 12rem;
		height: 1.7rem;
		font-family: PingFang SC;
border-bottom: 2px solid #f5f4ff;
	}
	.main-card2{
			padding-left: .38rem;
			border-bottom: 2px solid #f5f4ff;
		}
	.select-main {
		width: 1.34rem !important;
		margin-right: 1.4rem;
	}
	.day-item-main{
		display: flex;
	}
	.time-item-main {
		display: flex;
		margin-bottom: .3rem;
	}

	.logo-right {
		margin-left: 1.64rem;
		margin-bottom: .24rem;
	}

	.zhi {
		margin-right: .4rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #999999;
		line-height: .54rem;
	}

	.zhis {
		margin-right: .4rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #999999;
		line-height: .54rem;
	}

	.compute-img {
		margin-bottom: .16rem;
		width: 4rem;
		height: 2.4rem;
	}

	.compute-img img {
		width: 4rem;
		height: 2.4rem;
	}

	.recruit-content-compute {
		margin-bottom: .88rem;
	}

	/deep/.el-select .el-input .el-select__caret {
		font-size: .14rem !important;
	}

	.submit-item {
		display: flex;
		justify-content: center;
		align-items: center;
		height: .48rem;
	}

	.treatment-content-submit {
		margin-bottom: .7rem;
	}
.el-date-editor.el-input, .el-date-editor.el-input__inner{
	width: 2.4rem !important;
}
	/deep/.el-input__inner {
		width: 2.4rem !important;
		height: .48rem !important;
		font-size: .16rem;
		font-family: PingFang SC;
		color: #666666;
	}
/deep/.el-input .el-input--suffix{
	width: 2.4rem !important;
}
	/deep/.el-input--prefix .el-input__inner {
		padding-right: 0;
	}

	.el-select {
		width: 2.4rem;
	}

	.wanted {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.treatment-content-pipei {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		padding-top: .7rem;
	}

	.wanted-item-add {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 12.8rem;
		height: 1.60rem;
		background: #FFFFFF;
		border: 2px solid #762FEF;
		border-style: dotted;
		box-shadow: 0px 2px 12px 0px rgba(153, 153, 153, 0.15);
		cursor: pointer;
	}

	.wanted-item-add img {
		width: .21rem;
		height: .2rem;
	}

	.wanted-item-add span {
		margin-left: .12rem;
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #762FEF;
		line-height: 30px
	}

	.wanted-item-right {
		position: absolute;
		display: flex;
		right: 0;
		top: .6rem;
	}

	.wanted-item-right-delete {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2.46rem;
		height: .48rem;
		background: #FFFFFF;
		border: 1px solid #E41D1D;
		border-radius: 6px;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #E41D1D;
		cursor: pointer;
	}
	.wanted-item-right-deletes{
		margin-right: .4rem;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.2rem;
		height: .48rem;
		background: #FFFFFF;
		border: 2px solid #666666;
		border-radius: 4px;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #666666;
	}
	.wanted-item-right-changes{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.2rem;
		height: .48rem;
		background: #666666;
		border-radius: 4px;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #FFFFFF;
	}
	.wanted-item-right-change {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: .16rem;
		width: 2.46rem;
		height: .48rem;
		background: #E41D1D;
		border-radius: 6px;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}

	.wanted-item-left {
		width: 7rem;
	}

	.wanted-item-left-title {
		margin-bottom: .12rem;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		line-height: .30rem
	}

	.wanted-item-left-main {
		display: flex;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: .30rem
	}

	.price {
		width: 1.5rem;
	}

	.wanted-item-left-main .dizhi {
		margin-left: .4rem;
	}

	.wanted-item {
		padding-left: .4rem;		
		padding-right: .4rem;
		margin-bottom: .2rem;
		width: 12rem;
	}

	.wanted-item-main {
		position: relative;
		box-sizing: border-box;
		padding-right: .4rem;
		display: flex;
		box-sizing: border-box;		
		padding-top: .48rem;
		margin-bottom: .2rem;
		width: 11rem;
		height: 1.6rem;
		border-bottom: 2px solid #E9E9E9;
		
	}

	.wanted-state {
		display: flex;
		margin-bottom: .4rem;
	}

	.wanted-state span {
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		line-height: .54rem;
	}

	.wanted-top {
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		width: 11.6rem;
		margin-left: .38rem;
	}

	.top-wanted {
		font-size: .24rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		line-height: 30px;
	}

	.top-num {
		margin-right: .4rem;
		font-size: .24rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		line-height: 30px;
	}

	.top-num span {
		color: #762FEF;
	}

	.wanted-banner {
		margin-left: .38rem;
		margin-bottom: .28rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 30px;
	}

	/deep/.el-input__icon {
		font-size: .14rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.treatment-content-welfare {
		width: 8rem;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: .4rem;
	}

	.selfCheckBox {
		margin-right: .16rem;
		margin-bottom: .12rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #666666;
		cursor: pointer;
	}

	.selfCkeckImgBox-item {
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		width: 1.20rem;
		height: .42rem;
		background: rgb(228,29,29,0.1);
		border-radius: .06rem;
	}

	.selfCkeckImgBox-item img {
		margin-right: .08rem;
		width: .25rem;
		height: .24rem;
	}

	.selfCkeckImgBox-item2 {
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		width: 1.20rem;
		height: .42rem;
		background: #FFFFFF;
		border: 1px solid #E9E9E9;
		border-radius: .06rem;

	}

	.selfCkeckImgBox-item2 img {
		margin-right: .08rem;
		width: .25rem;
		height: .24rem;
	}

	.treatment-time-item {
		display: flex;
	}

	.treatment-day-item {
		display: flex;
	}

	.treatment-day-item img {
		width: .21rem;
		height: .2rem;
	}

	.treatment-day-item>span {
		margin-left: .12rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #666666;
		line-height: 30px
	}

	.treatment-time-item span {
		margin-left: .12rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #666666;
	}

	.treatment-content-time {
		display: flex;
		flex-direction: column;
		margin-bottom: .28rem;
	}

	.treatment-content-title {
		margin-bottom: .28rem;
		font-size: .24rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
	}

	.recruit-msg {
		display: flex;
		flex-direction: column;
	}

	.recruit-content-msg {
		display: flex;
		flex-direction: column;
	}

	.recruit-name {
		display: flex;
		margin-bottom: .32rem;
	}

	.recruit-name span {
		line-height: .52rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
	}

	.recruit {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.recruit-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		width: 19.2rem;
		padding: 0 3.6rem;
	}

	.main {
		padding-top: .7rem;	
		padding-bottom: .4rem;
		display: flex;
		flex-direction: column;
		width: 12rem;
		border-left: 1px solid rgba(172, 172, 172, 0.2);
		border-right: 1px solid rgba(172, 172, 172, 0.2);
		box-sizing: border-box;
	}

	.recruit-content-title {
		margin-bottom: .28rem;
		font-size: .24rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
	}

	.logo-set {
		margin-left: .06rem;
		width: .67rem;
		height: .28rem;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
		border-radius: 2px;
		overflow: hidden;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		text-align: center;
		line-height: .28rem;
		cursor: pointer;
	}

	.logo-img {
		margin-bottom: .12rem;
		width: .88rem;
		height: .88rem;
	}

	.logo-img img {
		width: .88rem;
		height: .88rem;
		border-radius: .44rem;
	}

	.hiddenInput {
		opacity: 0;
	}

	.hiddenInputs {
		opacity: 0;
	}
</style>
